import React, { useState,useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CustomJobTable} from '../../Components/Utils';
import LogoutBtn from '../../Components/LogoutBtn';
import BaseModal from '../../Components/Modal/BaseModal';
import AllJobEditModal from '../../Components/Modal/Edit/AllJobEditModal';
import service from '../../api/services/jobs'
import {toast} from 'react-toastify'
import TimeFormat from '../../Components/TimeFormat'

export default function AllJobs() {
    const highLight = useSelector((state) => state.selectedTab.initialState);
    const [loading,setLoading] = useState(false)
    const [selectedRow,setSelectedRow] = useState(null)
    const [openEditModal,setOpenEditModal] = useState(false)
    const [data,setData] = useState([])
    const header = ["JOB TYPE","MARKET ID","TIME FRAME","OCCURRENCE","STATUS","NEXT JOB","LAST JOB"]

    useEffect(()=>{
        getAllJobData()
    },[])

    useEffect(()=>{
        if(selectedRow){
          setOpenEditModal(true)
        }
      },[selectedRow])

   
    const handleEdit = (val)=>{
        setSelectedRow(val)
    }

    const getAllJobData = async()=>{
        setLoading(true)
        const payload={
            index: 0,
            length: 0
          }
        const res = await service.getCustomJobLogs(payload)
        if(res?.data?.data?.length){
            setData(res?.data?.data)
        }else{
            setData([])
        }
        setLoading(false)
    }

    const handleCloseEdit = ()=>{
        setSelectedRow(null)
        setOpenEditModal(false)

      }

    const handleUpdate = async (payload)=>{
        const res = await service.updateCustomJob(payload)
        if(!res?.data?.isError){
            toast.success("Job updated successfully")
            handleCloseEdit()
            getAllJobData()
        }else{
            handleCloseEdit()
            toast.error('Some error occurred while updating job')
        }
    }

return (
    <div className="flex w-full h-full  overflow-hidden px-8">
        {openEditModal &&
          <BaseModal>
            <AllJobEditModal handleClose={handleCloseEdit} selectedData = {selectedRow} handleUpdate={handleUpdate}/>
          </BaseModal>
        }     


        <div className="w-full h-full bg-white text-black text-left font-semibold text-28  ">
            
        <div className="flex flex-col">
          <div className="w-full flex items-center justify-between">
          <span className="text-black/70 text-left font-semibold text-28">
            All Jobs Configuration
          </span>
          <LogoutBtn />
          </div>
          {/* <span className="text-10 text-black/40 ">
            {highLight?.toUpperCase()}
          </span> */}
        </div>
          
        <div className='bg-white space-y-4 px-2 py-4'>
        {data?.length > 0 &&
            <div className='w-full flex items-center justify-end'>
            <TimeFormat />
          </div>}
          <div className=''>
            <CustomJobTable header={header} data={data} loading={loading} editClick={handleEdit} />
          </div>
        </div>
          
        </div>
    </div>
);
}
