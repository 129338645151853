import React, { useState  } from "react";
import { useSelector } from "react-redux";
import { ButtonComp, TablePage } from "../../../../Components/Utils";
import { SvgComponent } from "../../../../Components/SvgComponent";
import BaseModal from "../../../../Components/Modal/BaseModal";
import Symbols from "../../../../Components/Modal/Symbols"


export default function MarketAsx() {
  const [configureScheduler , setConfigureScheduler] = useState(false)
  const mainSliceTab = useSelector((state) => state.selectedTab.initialState);
  const SubSliceTab = useSelector((state) => state.SelectedSubTab.initialState);
  const schedulerSlice=useSelector( (state)=>state.SelectedDWMTab.initialState)
  
  const data = [
    {
      Job_Id: "02389",
      Exchnage: "ASX",
      Time_Frame: "Monthly",
      Candle_Update: "2423",
      Status: "Successful",
      Failed_Request : "02",
      Next_Job: "08/08/2023 02:00",
      Last_Job: "08/08/2023 02:00",
      View_Details : "Details"
    },
    {
      Job_Id: "02389",
      Exchnage: "ASX",
      Time_Frame: "Monthly",
      Candle_Update: "2423",
      Status: "Successful",
      Failed_Request : "02",
      Next_Job: "08/08/2023 02:00",
      Last_Job: "08/08/2023 02:00",
      View_Details : "Details"
    },
    {
      Job_Id: "02389",
      Exchnage: "ASX",
      Time_Frame: "Monthly",
      Candle_Update: "2423",
      Status: "Successful",
      Failed_Request : "02",
      Next_Job: "08/08/2023 02:00",
      Last_Job: "08/08/2023 02:00",
      View_Details : "Details"
    },
    {
      Job_Id: "02389",
      Exchnage: "ASX",
      Time_Frame: "Monthly",
      Candle_Update: "2423",
      Status: "Successful",
      Failed_Request : "02",
      Next_Job: "08/08/2023 02:00",
      Last_Job: "08/08/2023 02:00",
      View_Details : "Details"
    },
    {
      Job_Id: "02389",
      Exchnage: "ASX",
      Time_Frame: "Monthly",
      Candle_Update: "2423",
      Status: "Successful",
      Failed_Request : "02",
      Next_Job: "08/08/2023 02:00",
      Last_Job: "08/08/2023 02:00",
      View_Details : "Details"
    },
    {
      Job_Id: "02389",
      Exchnage: "ASX",
      Time_Frame: "Monthly",
      Candle_Update: "2423",
      Status: "Successful",
      Failed_Request : "02",
      Next_Job: "08/08/2023 02:00",
      Last_Job: "08/08/2023 02:00",
      View_Details : "Details"
    },
    {
      Job_Id: "02389",
      Exchnage: "ASX",
      Time_Frame: "Monthly",
      Candle_Update: "2423",
      Status: "Successful",
      Failed_Request : "02",
      Next_Job: "08/08/2023 02:00",
      Last_Job: "08/08/2023 02:00",
      View_Details : "Details"
    },
    {
      Job_Id: "02389",
      Exchnage: "ASX",
      Time_Frame: "Monthly",
      Candle_Update: "2423",
      Status: "Successful",
      Failed_Request : "02",
      Next_Job: "08/08/2023 02:00",
      Last_Job: "08/08/2023 02:00",
      View_Details : "Details"
    },
    {
      Job_Id: "02389",
      Exchnage: "ASX",
      Time_Frame: "Monthly",
      Candle_Update: "2423",
      Status: "Successful",
      Failed_Request : "02",
      Next_Job: "08/08/2023 02:00",
      Last_Job: "08/08/2023 02:00",
      View_Details : "Details"
    },
    {
      Job_Id: "02389",
      Exchnage: "ASX",
      Time_Frame: "Monthly",
      Candle_Update: "2423",
      Status: "Successful",
      Failed_Request : "02",
      Next_Job: "08/08/2023 02:00",
      Last_Job: "08/08/2023 02:00",
      View_Details : "Details"
    },
    {
      Job_Id: "02389",
      Exchnage: "ASX",
      Time_Frame: "Monthly",
      Candle_Update: "2423",
      Status: "Successful",
      Failed_Request : "02",
      Next_Job: "08/08/2023 02:00",
      Last_Job: "08/08/2023 02:00",
      View_Details : "Details"
    },
    {
      Job_Id: "02389",
      Exchnage: "ASX",
      Time_Frame: "Monthly",
      Candle_Update: "2423",
      Status: "Successful",
      Failed_Request : "02",
      Next_Job: "08/08/2023 02:00",
      Last_Job: "08/08/2023 02:00",
      View_Details : "Details"
    },
     {
      Job_Id: "02389",
      Exchnage: "ASX",
      Time_Frame: "Monthly",
      Candle_Update: "2423",
      Status: "Successful",
      Failed_Request : "02",
      Next_Job: "08/08/2023 02:00",
      Last_Job: "08/08/2023 02:00",
      View_Details : "Details"
    },
    {
      Job_Id: "02389",
      Exchnage: "ASX",
      Time_Frame: "Monthly",
      Candle_Update: "2423",
      Status: "Successful",
      Failed_Request : "02",
      Next_Job: "08/08/2023 02:00",
      Last_Job: "08/08/2023 02:00",
      View_Details : "Details"
    },
    {
      Job_Id: "02389",
      Exchnage: "ASX",
      Time_Frame: "Monthly",
      Candle_Update: "2423",
      Status: "Successful",
      Failed_Request : "02",
      Next_Job: "08/08/2023 02:00",
      Last_Job: "08/08/2023 02:00",
      View_Details : "Details"
    },
    {
      Job_Id: "02389",
      Exchnage: "ASX",
      Time_Frame: "Monthly",
      Candle_Update: "2423",
      Status: "Successful",
      Failed_Request : "02",
      Next_Job: "08/08/2023 02:00",
      Last_Job: "08/08/2023 02:00",
      View_Details : "Details"
    },
    {
      Job_Id: "02389",
      Exchnage: "ASX",
      Time_Frame: "Monthly",
      Candle_Update: "2423",
      Status: "Successful",
      Failed_Request : "02",
      Next_Job: "08/08/2023 02:00",
      Last_Job: "08/08/2023 02:00",
      View_Details : "Details"
    },
    {
      Job_Id: "02389",
      Exchnage: "ASX",
      Time_Frame: "Monthly",
      Candle_Update: "2423",
      Status: "Successful",
      Failed_Request : "02",
      Next_Job: "08/08/2023 02:00",
      Last_Job: "08/08/2023 02:00",
      View_Details : "Details"
    },
    {
      Job_Id: "02389",
      Exchnage: "ASX",
      Time_Frame: "Monthly",
      Candle_Update: "2423",
      Status: "Successful",
      Failed_Request : "02",
      Next_Job: "08/08/2023 02:00",
      Last_Job: "08/08/2023 02:00",
      View_Details : "Details"
    },
    {
      Job_Id: "02389",
      Exchnage: "ASX",
      Time_Frame: "Monthly",
      Candle_Update: "2423",
      Status: "Successful",
      Failed_Request : "02",
      Next_Job: "08/08/2023 02:00",
      Last_Job: "08/08/2023 02:00",
      View_Details : "Details"
    }, {
      Job_Id: "02389",
      Exchnage: "ASX",
      Time_Frame: "Monthly",
      Candle_Update: "2423",
      Status: "Successful",
      Failed_Request : "02",
      Next_Job: "08/08/2023 02:00",
      Last_Job: "08/08/2023 02:00",
      View_Details : "Details"
    },

    {
      Job_Id: "02389",
      Exchnage: "ASX",
      Time_Frame: "Monthly",
      Candle_Update: "2423",
      Status: "Successful",
      Failed_Request : "02",
      Next_Job: "08/08/2023 02:00",
      Last_Job: "08/08/2023 02:00",
      View_Details : "Details"
    },
    {
      Job_Id: "02389",
      Exchnage: "ASX",
      Time_Frame: "Monthly",
      Candle_Update: "2423",
      Status: "Successful",
      Failed_Request : "02",
      Next_Job: "08/08/2023 02:00",
      Last_Job: "08/08/2023 02:00",
      View_Details : "Details"
    },
    {
      Job_Id: "02389",
      Exchnage: "ASX",
      Time_Frame: "Monthly",
      Candle_Update: "2423",
      Status: "Successful",
      Failed_Request : "02",
      Next_Job: "08/08/2023 02:00",
      Last_Job: "08/08/2023 02:00",
      View_Details : "Details"
    },
    {
      Job_Id: "02389",
      Exchnage: "ASX",
      Time_Frame: "Monthly",
      Candle_Update: "2423",
      Status: "Successful",
      Failed_Request : "02",
      Next_Job: "08/08/2023 02:00",
      Last_Job: "08/08/2023 02:00",
      View_Details : "Details"
    },
    {
      Job_Id: "02389",
      Exchnage: "ASX",
      Time_Frame: "Monthly",
      Candle_Update: "2423",
      Status: "Successful",
      Failed_Request : "02",
      Next_Job: "08/08/2023 02:00",
      Last_Job: "08/08/2023 02:00",
      View_Details : "Details"
    },
    {
      Job_Id: "02389",
      Exchnage: "ASX",
      Time_Frame: "Monthly",
      Candle_Update: "2423",
      Status: "Successful",
      Failed_Request : "02",
      Next_Job: "08/08/2023 02:00",
      Last_Job: "08/08/2023 02:00",
      View_Details : "Details"
    },
    {
      Job_Id: "02389",
      Exchnage: "ASX",
      Time_Frame: "Monthly",
      Candle_Update: "2423",
      Status: "Successful",
      Failed_Request : "02",
      Next_Job: "08/08/2023 02:00",
      Last_Job: "08/08/2023 02:00",
      View_Details : "Details"
    },
    {
      Job_Id: "02389",
      Exchnage: "ASX",
      Time_Frame: "Monthly",
      Candle_Update: "2423",
      Status: "Successful",
      Failed_Request : "02",
      Next_Job: "08/08/2023 02:00",
      Last_Job: "08/08/2023 02:00",
      View_Details : "Details"
    },
    {
      Job_Id: "02389",
      Exchnage: "ASX",
      Time_Frame: "Monthly",
      Candle_Update: "2423",
      Status: "Successful",
      Failed_Request : "02",
      Next_Job: "08/08/2023 02:00",
      Last_Job: "08/08/2023 02:00",
      View_Details : "Details"
    },
    
  ];
  

  return (
    <div className="flex w-full h-full  overflow-hidden px-8">
     {configureScheduler &&
      <BaseModal>
      <Symbols
      StateName={configureScheduler}
      setStated={setConfigureScheduler}
      header={mainSliceTab}
      market={SubSliceTab}
      timePeriod={schedulerSlice}
      
      />
      </BaseModal> 
       
      }
      <div className="w-full h-full bg-white text-black text-left font-semibold text-28  ">
        <div className="flex flex-col ">
          <span className="text-black text-left font-semibold text-28">
            PRICING JOB HISTORY 
          </span>
          <div className="flex items-center">
          <span className="text-10 text-black/40 ">
            {mainSliceTab.toUpperCase()}
          </span>
          <SvgComponent
              svgName={"CaretRight"}
              className={"h-3 w-3 fill-border/50"}
            />
          <span className="flex items-center text-10 text-black/40 text-left underline ">
            {SubSliceTab.toUpperCase()}
            <SvgComponent
              svgName={"CaretRight"}
              className={"h-3 w-3 fill-border/50"}
            />
          </span>
          <span className="flex items-center text-10 text-black/40 text-left underline ">
            {schedulerSlice}
            
          </span>
          
          </div>
        </div>
<div className="flex justify-end items-center text-10 text-gray font-bold">
<div className="px-6">
<ButtonComp 
BtnClass={`h-8 w-32`}
text={`Configure Scheduler`}
TextClass={`text-6 font-light`}
onClick={()=>{
  setConfigureScheduler(true)
}}
/>
</div>
</div>

    <div className="bg-white px-2 py-14">
      <div>
<TablePage 
data={data}

/>

      </div>
      </div> 

       
      </div>
    </div>
  );
}
