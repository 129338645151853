import React,{useEffect, useState} from 'react'
import { SvgComponent } from '../../SvgComponent'
import { TextInput,ButtonComp } from '../../Utils'
import services from '../../../api/services/jobs'

function AllJobEditModal({handleClose=()=>{},selectedData={},handleUpdate=()=>{}}) {
  const [editData,setEditData] = useState({})
  const [symbolList,setSymbolList] = useState([])
  const [data,setData] = useState()
  const weekDays = [{value:"1",name:"Monday"},{value:"2",name:"Tuesday"},{value:"3",name:"Wednesday"},{value:"4",name:"Thursday"},{value:"5",name:"Friday"}]
  const monthDay = [{name:"Start of Month",value:"S"},{name:"End of Month",value:"E"},{name:"Custom",value:"C"},]
  const dropdownVal = [{name:"Daily",value:"D"},{name:"Weekly",value:"W"},{name:"Monthly",value:"M"}]
  const marketId = [{name:"AU",value:"AU"},{name:"AU Index",value:"AU~INDX"},{name:"US",value:"US"},{name:"US Index",value:"US~INDX"},{name:"CA",value:"CA"},{name:"CA Index",value:"CA~INDX"},]

  useEffect(()=>{
    getSymbolList()
    setEditData(selectedData)
  },[])

  const handleEdit = ()=>{
    const updatedObject = {
      ...editData,
      jobId: editData?.id,
      jobTime: getTimeInSeconds(editData?.jobTime)
    };
    
    handleUpdate(updatedObject)
  }

  const getSymbolList = async() => {
    const res = await services.getJobSymbolList("CA~INDX")
    if(res?.data?.data?.data?.length){
      setSymbolList(res?.data?.data?.data)
    }else{
      setSymbolList([])
    }
  }



  const handleChange = (key,value)=>{
    setEditData(prevData => ({
      ...prevData,
      [key]: value,
    }));
  }
 
  const getTimeInSeconds = (val)=>{
    const dateTimeObject = new Date(val);
    const timestampInSeconds = dateTimeObject.getTime() / 1000;
    console.log("timeInSeconds",timestampInSeconds)
    return timestampInSeconds
  }

  const formattedDate = (val)=>{
    if(val){
      const formattedDateTime = new Date(val)?.toISOString()?.slice(0, 16);
      return formattedDateTime
    }
  }
 
  return (
    <div className='w-[40vw] h-auto overflow-y-auto max-h-[65vh] space-y-2 p-2'>
    <div className='w-full pl-2 border-b border-gray flex items-center justify-between h-[8%]'>
        <div className='text-24 text-black/50'></div>
        <div onClick={handleClose} className='p-2 hover:bg-black/20 rounded-full cursor-pointer'>
            <SvgComponent svgName="cross" className="w-5 h-5 text-black/60" />
        </div>
    </div>

    <div className='w-full space-y-4 py-4 px-2 h-full'>
          <div className='w-full flex items-center space-x-4'>
          <div className='w-6/12'>
          <div className='text-16 font-bold text-black/60'>Repeat On</div>
            <select onChange={(e)=>handleChange('occurrence',e.target.value)} id="repeat" value={editData?.occurrence} className="h-12 w-full px-2 mt-2 place-items-center text-16 bg-transparent border border-gray rounded-md placeholder-border outline-none">
              <option selected>Choose a repeat</option>
              {dropdownVal?.map((item,i)=>(
                <option value={item?.value}>{item?.name}</option>
              ))} 
             
            </select>
          </div>
          <div className='w-6/12'>
          <div className='text-16 font-bold text-black/60'>Time Frame</div>
          <select id="timeframe" value={editData?.timeFrame} onChange={(e)=>handleChange('timeFrame',e.target.value)} className="h-12 w-full px-2 mt-2 place-items-center text-16 bg-transparent border border-gray rounded-md placeholder-border outline-none">
              <option selected>Choose a time frame</option>
              {dropdownVal?.map((item,i)=>(
                <option value={item?.value}>{item?.name}</option>
              ))} 
             
            </select>
          </div>
          </div>
          <div className='w-full flex items-center space-x-4'>
          {/* <div className='w-6/12'>
          <div className='text-16'>Month Day</div>
          <input
            placeholder="Run time"
            className="h-12 w-full px-2 mt-2 place-items-center text-16 bg-transparent border border-gray rounded-md placeholder-border outline-none"
            type="calender"
            // value={time}
            // onChange={(e)=>setTime(e.target.value)}
            autoComplete={false}
            />
          </div> */}
          <div className='w-6/12'>
          <div className='text-16 font-bold text-black/60'>Job Time</div>
          <input
            placeholder="Job time"
            className="h-12 w-full px-2 mt-2 place-items-center text-16 bg-transparent border border-gray rounded-md placeholder-border outline-none"
            type="datetime-local"
            value={formattedDate(editData?.jobTime)}
            autoComplete={false}
            onChange={(e)=>handleChange("jobTime",e.target.value)}
            />
          </div>
          <div className='w-6/12'>
          <div className='text-16 font-bold text-black/60'>Job Type</div>
          <input
            placeholder="Job type"
            className="h-12 w-full px-2 mt-2 place-items-center text-16 bg-transparent border border-gray rounded-md placeholder-border outline-none"
            type="text"
            value={editData?.jobType}
            onChange={(e)=>handleChange("jobType",e.target.value)}
            autoComplete={false}
            />
          </div>
          </div>
          <div className='w-full flex items-center space-x-4'>
          <div className='w-6/12 '>
            {editData?.occurrence == "M" ? 
            <>            
            <div className={`text-16 font-bold ${editData?.occurrence == "D" ? 'text-black/30':'text-black/60'}`}>Month Day</div>
              <select id="monthday" value={editData?.monthType} disabled={editData?.occurrence == "D"} onChange={(e)=>handleChange('monthType',e.target.value)} className={`h-12 w-full px-2 mt-2 place-items-center text-16 bg-transparent border border-gray rounded-md placeholder-border outline-none `}>
              <option selected>Choose a month day</option>
              {monthDay?.map((item,i)=>(
                <option value={item?.value}>{item?.name}</option>
              ))} 
             
            </select>
            </>
              :
              <>
              <div className={`text-16 font-bold  ${editData?.occurrence == "D" ? 'text-black/30':'text-black/60'}`}>Week Day</div>
          <select id="marketid" value={editData?.weekDay} disabled={editData?.occurrence == "D"} onChange={(e)=>handleChange('weekDay',e.target.value)} className={`h-12 w-full px-2 mt-2 place-items-center text-16 bg-transparent border border-gray rounded-md placeholder-border outline-none `}>
              <option selected>Choose a weekday</option>
              {weekDays?.map((item,i)=>(
                <option value={item?.value}>{item?.name}</option>
              ))} 
             
            </select>
            </>
            }
          </div>
          <div className='w-6/12'>
          <div className='text-16 text-black/60 font-bold'>Market Id</div>
          <select id="marketid" onChange={(e)=>handleChange('marketId',e.target.value)} value={editData?.marketId} className="h-12 w-full px-2 mt-2 place-items-center text-16 bg-transparent border border-gray rounded-md placeholder-border outline-none">
              <option selected>Choose a market id</option>
              {marketId?.map((item,i)=>(
                <option value={item?.value}>{item?.name}</option>
              ))} 
             
            </select>
          </div>
          {/* <div className='w-6/12'>
          <div className='text-16'>Job Type</div>
          <input
            placeholder="Run time"
            className="h-12 w-full px-2 mt-2 place-items-center text-16 bg-transparent border border-gray rounded-md placeholder-border outline-none"
            type="text"
            value="Data Job"
            // onChange={(e)=>setTime(e.target.value)}
            autoComplete={false}
            />
          </div> */}
          </div>
          <div className='w-full flex items-center space-x-4'>
          {editData?.monthType == "C" && 
          <div className='w-6/12'>
          <div className='text-16'>Day of Month</div>
          <input
            placeholder="day of month"
            className="h-12 w-full px-2 mt-2 place-items-center text-16 bg-transparent border border-gray rounded-md placeholder-border outline-none"
            type="number"
            value={editData?.monthDay}
            min={0}
            max={31}
            onChange={(e)=>handleChange("monthDay",e.target.value)}
            autoComplete={false}
            />
          </div>}
       

          {/* <div className='w-6/12'>
          <div className='text-16'>Symbols</div>
          <select multiple id="symbols" value={editData?.symbolList} onChange={(e)=>handleChange("symbolList",e.target.value)} class="w-full px-2 mt-2 place-items-center text-16 bg-transparent border border-gray rounded-md placeholder-border outline-none">
           {symbolList?.map((item,i)=>(
             <option value={item?.symbol}>{item?.symbol}</option>
           ))}
          </select>
          </div> */}
          </div>
        </div>
   
    <div className='w-full pl-2 border-t py-2 pr-2 border-gray space-x-6 flex items-center justify-end h-[8%]'>
      <ButtonComp text={"Update Job"} BtnClass={`h-10 w-2/12`} TextClass='text-16 tracking-wider' onClick={handleEdit}/>

      <ButtonComp text={"Cancel"}  BtnClass={`h-10 w-2/12 bg-[gray]/70 hover:bg-[gray]/80`} TextClass='text-16 tracking-wider' onClick={handleClose}/>
      
    </div>
</div>
  )
}

export default AllJobEditModal