import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { DataJobTable } from "../../Components/Utils";
import LogoutBtn from "../../Components/LogoutBtn";
import service from "../../api/services/jobs";
import { toast } from "react-toastify";
import TimeFormat from "../../Components/TimeFormat";

export default function DataJobs() {
  const highLight = useSelector((state) => state.selectedTab.initialState);
  const [currentTab, setCurrentTab] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedLimit, setSelectedLimit] = useState(10);
  const [data, setData] = useState([]);
  const header = [
    "MARKET ID",
    "OCCURRENCE",
    "CANDLE UPDATE",
    "JOB TIME",
    "LAST RUN",
  ];

  useEffect(() => {
    getJobData();
  }, []);

  const handleDelete = (val) => {
    console.log("delete", val);
  };

  const getJobData = async () => {
    setLoading(true);
    const payload = {
      index: 0,
      length: 20,
    };
    const res = await service.getDataJobLogs(payload);
    if (res?.data?.data?.list?.length) {
      setData(res?.data?.data?.list);
    } else {
      setData([]);
    }
    setLoading(false);
  };

  const handleLimitChange = (limit) => {
    setSelectedLimit(limit);
  };

  return (
    <div className="flex w-full h-full  overflow-hidden px-8">
      <div className="w-full h-full bg-white text-black text-left font-semibold text-28  ">
        <div className="flex flex-col">
          <div className="w-full flex items-center justify-between">
            <span className="text-black/70 text-left font-semibold text-28">
              Data Jobs Configuration
            </span>
            <LogoutBtn />
          </div>
        </div>

        <div className="bg-white space-y-4 px-2 py-4">
          {data?.length > 0 && (
            <div className="w-full flex items-center justify-end">
              {/* <div>
            <select
              value={selectedLimit}
              onChange={(e) => handleLimitChange(e.target.value)}
              className="block w-full bg-white border border-gray-300 p-2 rounded-md"
            >
              <option value={10}>10</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value="ALL">ALL</option>
            </select>
            </div> */}
              <div>
                <TimeFormat />
              </div>
            </div>
          )}
          <div className="">
            <DataJobTable
              header={header}
              data={data}
              loading={loading}
              deleteClick={handleDelete}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
