import React,{useState,useEffect} from 'react'
import LogoutBtn from '../../Components/LogoutBtn';
import {useSelector} from 'react-redux'
import ReactQuill from 'react-quill';
import { ButtonComp } from '../../Components/Utils';
import {toast} from 'react-toastify'
import services from '../../api/services/jobs'


function EmailTemplate() {
    const highLight = useSelector((state) => state.selectedTab.initialState);
    const [value, setValue] = useState('');
    const [loading,setLoading] = useState(false)
    const [templateType,setTemplateType] = useState('')
    const options = [{name:"Two FA",value:"two_fa"},{name:"Forget Password",value:"forget_password"},{name:"Account Creation",value:"account_creation"}]
    const modules = {
        toolbar: [
          [{ 'header': []}],
          ['bold', 'italic', 'underline', 'strike'],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          ['link'],
          [{ 'align': [] }],
          ['image'],
          [{ 'color': [] }, { 'background': [] }],
        ],
      };

    useEffect(()=>{
        getAllTemplate()
    },[])


    const getAllTemplate = async()=>{
        const res = await services.getAllTemplate()
        if(res?.data?.payload?.length){
            setValue(res?.data?.payload[5]?.template)
        }else{
            setValue('')
        }
    }


    const handleSubmit = async()=>{
        setLoading(true)
        if(value && templateType){
            const payload = {
                template:value,
                templateFor:templateType 
            }
            const res = await services.insertTemplate(payload)
            if(res?.data?.message){
                toast.success(res?.data?.message)
            }else{
                toast.error("Error occured while saving template")
            }
        }else{
            toast.warning("Template type and content is required")
        }
        setLoading(false)
    }

  return (
    <div className="flex w-full h-full  overflow-hidden px-8">
       


    <div className="w-full h-full bg-white text-black text-left font-semibold text-28  ">
        
    <div className="flex flex-col">
      <div className="w-full flex items-center justify-between">
      <span className="text-black/70 text-left font-semibold text-28">
        EMAIL TEMPLATE
      </span>
      <LogoutBtn />
      </div>
      <span className="text-10 text-black/40 ">
        {highLight?.toUpperCase()}
      </span>
    </div>
      
    <div className='bg-white px-2 space-y-6 pt-6 pb-6'>
        <div className='flex w-full justify-end'>
            <div className='w-2/12'>
            <select id="countries" onChange={(e)=>setTemplateType(e.target.value == "nonselected" ? '' : e.target.value)} class="border border-black/20 text-16 p-2 focus:outline-none text-black/60  rounded-md w-full">
            <option selected value="nonselected">Template type</option>
                {options?.map((item,i)=>(
                     <option key={i} value={item?.value}>{item?.name}</option>
                ))}
            </select>
            </div>

        </div>
        <ReactQuill theme="snow" value={value} onChange={setValue} modules={modules} />
    </div>
    <div className='w-full flex justify-end'>
        <div className='w-32'>
            <ButtonComp text={loading ? 'Sending...':'Send'} BtnClass={`h-12 w-full`} TextClass='text-16 tracking-wider' onClick={handleSubmit} />
        </div>
        
    </div>  
    </div>
</div>
  )
}

export default EmailTemplate